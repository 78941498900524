import { useMemo } from "react";
import { IOptionData, IOptionType } from "src/interfaces/option-type.interface";
import { IService } from "src/pages/service/interface/service.interface";
import { apiRequest } from "./axios";

export const TIMINGS: Array<IOptionType> = [
    { value: "00:00", label: "00:00" },
    { value: "01:00", label: "01:00" },
    { value: "02:00", label: "02:00" },
    { value: "03:00", label: "03:00" },
    { value: "04:00", label: "04:00" },
    { value: "05:00", label: "05:00" },
    { value: "06:00", label: "06:00" },
    { value: "07:00", label: "07:00" },
    { value: "08:00", label: "08:00" },
    { value: "09:00", label: "09:00" },
    { value: "10:00", label: "10:00" },
    { value: "11:00", label: "11:00" },
    { value: "12:00", label: "12:00" },
    { value: "13:00", label: "13:00" },
    { value: "14:00", label: "14:00" },
    { value: "15:00", label: "15:00" },
    { value: "16:00", label: "16:00" },
    { value: "17:00", label: "17:00" },
    { value: "18:00", label: "18:00" },
    { value: "19:00", label: "19:00" },
    { value: "20:00", label: "20:00" },
    { value: "21:00", label: "21:00" },
    { value: "22:00", label: "22:00" },
    { value: "23:00", label: "23:00" },
];

export const WEEKS: Array<IOptionType> = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thrusday", label: "Thrusday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
];

export const PERMISSION_LEVEL: Array<IOptionType> = [
    { value: "1", label: "View" },
    { value: "2", label: "View, Edit" },
    { value: "3", label: "View, Add" },
    { value: "4", label: "View, Delete" },
    { value: "5", label: "View, Add, Edit" },
    { value: "6", label: "View, Add, Delete" },
    { value: "7", label: "View, Edit, Delete" },
    { value: "8", label: "View, Add, Edit, Delete" },
];

export const YES_NO: Array<IOptionType> = [
    { value: "1", label: "Yes" },
    { value: "0", label: "No" },
];

export const EMPTY_OPTION = { value: "", label: "" };

// export const fillEmptyOption = (option?: IOptionType) => option && option.value ? option : EMPTY_OPTION;
export const fillEmptyOption = (option?: IOptionType) => {
    if (!option || !option.value) {
        return EMPTY_OPTION;
    }
    return option;
};


export const getSelectionOptionFromOptions = (options: Array<IOptionType>, value: string | number): IOptionType | undefined =>
    options.find(option => option.value === value || option.label === value);

export const getSelectionOptionFromOptionsByLabel = (
    options: Array<IOptionType>, 
    value: string
): IOptionType => {
    const EMPTY_OPTION: IOptionType = { label: '', value: '' }; // Define your fallback option here
    return options.find(option => option.value === value) || EMPTY_OPTION;
};

type StateType = {
    value: string;
    label: Record<string, string>; // `label` is a map of province codes to province names
};

export const getFormattedProvinces = (
    states: Array<{ value: string; label: Record<string, string> }>,
    countryValue: string
): Array<{ value: string; label: string }> => {
    const countryProvinces = states.find((item) => item.value === countryValue)?.label || {};
    return Object.entries(countryProvinces).map(([key, value]) => ({
        value: key,
        label: value as string,
    }));
};



export const getOptionLabel = (key: string, value: string): string =>
    getSelectedOption(key, value)?.label ?? "";

export const getSelectedOption = (key: string, value: string): IOptionType | undefined =>
    getStorageConsts<IOptionType>(key).find(option => option.value === value);


export const useOptions = (key: string): Array<IOptionType> =>
    useMemo(() => getStorageConsts<IOptionType>(key), [key]);

const getStorageConsts = <T>(key: string): Array<T> => {
    try {
        const consts = window.localStorage.getItem("constants");
        if (!consts) {
            if (!consts) throw new Error(`Constant not available at the moment`);
        }
        const result: { [key: string]: Array<T> } = JSON.parse(consts) as any;
        if (!Object.prototype.hasOwnProperty.call(result, key)) {
            throw new Error(`Constant for ${key} not available at the moment`);
        }
        return result[key];
    } catch (error) {
        console.log(error.message);
    }
    return [];
};

export const getUserFromLocalStorage = (key: string = "user"): any | null => {
    try {
        const user = localStorage.getItem(key);
        return user ? JSON.parse(user) : null;
    } catch (error) {
        console.error("Error getting user from localStorage:", error);
        return null;
    }
};

export const getGroupMasterList = async () => {
    const res: any = await apiRequest(`groups/list`);
    return res.data as Array<IOptionType>;
};

export const getServiceMasterList = async () => {
    const res: any = await apiRequest(`services/list`);
    return res.data as Array<IOptionData<IService>>;
};

export const getYardMasterList = async () => {
    const res: any = await apiRequest(`yards/list`);
    return res.data as Array<IOptionType>;
};

export const getVendorMasterList = async () => {
    const res: any = await apiRequest(`vendors/list`);
    return res.data as Array<IOptionType>;
};

export const getContainerSizeMasterList = async () => {
    const res: any = await apiRequest(`container_sizes/list`);
    return res.data as Array<IOptionType>;
};

export const getShiplineMasterList = async () => {
    const res: any = await apiRequest(`shiplines/list`);
    return res.data as Array<IOptionType>;
};

export const getCommodityMasterList = async () => {
    const res: any = await apiRequest(`commodities/list`);
    return res.data as Array<IOptionType>;
};

export const getCompanyMasterList = async () => {
    const res: any = await apiRequest(`companies/list`);
    return res.data as Array<IOptionType>;
};

export const getCityMasterList = async (province?: string): Promise<Array<IOptionType>> => {
    try {
        if (!province) {
            console.warn('Province is not provided, returning an empty array.');
            return [];
        }

        const res: any = await apiRequest(`cities/list`, { province });
        if (res && res.data && Array.isArray(res.data)) {
            return res.data as Array<IOptionType>;
        } else {
            console.warn('Invalid response format from the API:', res);
            return [];
        }
    } catch (error) {
        console.error('Error fetching city master list:', error);
        return [];
    }
};


export const getClientMasterList = async (shipment_transport_type?: string) => {
    const res: any = await apiRequest(`clients/list`, { shipment_transport_type });
    return res.data as Array<IOptionType>;
};

export const getClientContactMasterList = async (clientId: string) => {
    const res: any = await apiRequest(`clients/contacts/list`, { client_id: clientId });
    return res.data as Array<IOptionType>;
};

export const getClientAPEmails = async (clientId: string) => {
    const res: any = await apiRequest(`clients/show`, { id: clientId });
    if (res.data.ap_email) {
        const apEmailsString: string = res.data.ap_email;
        const apEmailsArray: Array<IOptionType> = apEmailsString.split(',').map(email => ({ label: email.trim(), value: email.trim() }));
        return apEmailsArray;
    }
    return [];
};

export const getClientServiceLocationMasterList = async (clientId: string) => {
    const res: any = await apiRequest(`clients/service_locations/list`, { client_id: clientId });
    return res.data as Array<IOptionType>;
};

export const getZoneCityMasterList = async (zoneId?: string) => {
    const res: any = await apiRequest(`zones/cities/list`, { zone_id: zoneId });
    return res.data as Array<IOptionType>;
};

export const getZoneMasterList = async () => {
    const res: any = await apiRequest(`zones/list`);
    return res.data as Array<IOptionType>;
};

export const getTruckMasterList = async (zoneId?: string) => {
    const res: any = await apiRequest(`trucks/list`, { zone_id: zoneId });
    return res.data as Array<IOptionType>;
};

export const getChassisMasterList = async () => {
    const res: any = await apiRequest(`chassis/list`);
    return res.data as Array<IOptionType>;
};

export const getRunTypeMasterList = async () => {
    const res: any = await apiRequest(`run_types/list`);
    return res.data as Array<IOptionType>;
};

export const getDriverMasterList = async () => {
    const res: any = await apiRequest(`drivers/list`);
    return res.data as Array<IOptionType>;
};