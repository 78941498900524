import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { authRequest, endpoints } from 'src/utils/axios';
import { resetPagesPermissions } from 'src/context/permission.context';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';
import { msalInstance } from 'src/config/msalConfig'; // MSAL instance for Microsoft SSO
import axios from 'axios';

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const temp = window.localStorage.getItem("user");
        const user = temp && JSON.parse(temp);

        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const processLoginResponse = (response: any) => {
    const { access_token, constants, email, first_name, last_name, shipment_access } = response;
  
    window.localStorage.setItem("accessToken", JSON.stringify(access_token));
    setSession(access_token);
    window.localStorage.setItem("constants", JSON.stringify(constants));
    window.localStorage.setItem("user", JSON.stringify({
      email,
      name: `${first_name} ${last_name}`,
      shipment_access: shipment_access,
    }));

    const user = {
      email,
      name: `${first_name} ${last_name}`,
    };
  
    resetPagesPermissions();
  
    dispatch({
      type: Types.LOGIN,
      payload: {
        user,
      },
    });
  };
  const login = useCallback(async (email: string, password: string) => {
    try {
      const res: any = await authRequest(`auth/login`, { email, password });
      processLoginResponse(res.data);
    } catch (error) {
      // console.error('Login failed:', error);
      throw new Error('Login failed. Please try again.');
    }
  }, []);
  const handleLoginWithMicrosoft = useCallback(async () => {
    try {
      // Attempt to log in with Microsoft SSO
      const loginResponse = await msalInstance.loginPopup({
        scopes: ['openid', 'profile', 'email', 'User.Read'],
      });
  
      const microsoftAccessToken = loginResponse.accessToken;
  
      // Send the Microsoft access token to your backend for validation
      const res: any = await authRequest(`auth/microsoft/callback`, {
        token: microsoftAccessToken,
      });
  
      // Handle the response
      const responseData = res.data.data || res.data; // Handle potential inconsistencies
      processLoginResponse(responseData);
    } catch (error) {
      console.error('Microsoft SSO failed:', error);
      throw new Error('Microsoft SSO failed. Please try again.');
    }
  }, []);

  

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const res: any = await authRequest(endpoints.auth.register, {
        email,
        password,
        firstName,
        lastName,
      });
      const { accessToken, user } = res.data;
      sessionStorage.setItem(STORAGE_KEY, accessToken);
      dispatch({
        type: Types.REGISTER,
        payload: {
          user,
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      login,
      register,
      logout,
      handleLoginWithMicrosoft,
    }),
    [login, logout, register, handleLoginWithMicrosoft, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
