import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import PermissionDeniedPage from 'src/pages/dashboard/Permission-denied-page';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));


// zone
const Demo = lazy(() => import('src/sections/demo/demo'))

const CompanyList = lazy(() => import("src/pages/company/company-list"))
const CompanyForm = lazy(() => import("src/pages/company/company-form"))

const CityList = lazy(() => import("src/pages/city/city-list"))
const CityForm = lazy(() => import("src/pages/city/city-form"))

const ZoneList = lazy(() => import("src/pages/zone/zone-list"))
const ZoneForm = lazy(() => import("src/pages/zone/zone-form"))

const ServiceList = lazy(() => import("src/pages/service/service-list"))
const ServiceForm = lazy(() => import("src/pages/service/service-form"))

const RunTypeList = lazy(() => import("src/pages/run-type/run-type-list"))

const VendorList = lazy(() => import("src/pages/vendor/vendor-list"))
const VendorForm = lazy(() => import("src/pages/vendor/vendor-form"))

// trucks module
const TruckListPage = lazy(() => import("src/pages/truck/truck-list"))
const TruckFormPage = lazy(() => import("src/pages/truck/truck-form"))
const TruckTabNavigatorView = lazy(() => import("src/pages/truck/view/truck-tab-navigator-view"))

// chasis

const ChassisListPage = lazy(() => import("src/pages/chassis/chassis-list"))
const ChassisFormPage = lazy(() => import("src/pages/chassis/chassis-form"))

const ChassisTabNavigatorView = lazy(() => import("src/sections/chassis/view/chassis-tab-navigator-view"))

// yard

const YardListPage = lazy(() => import("src/pages/yard/yard-list"))
const YardLocations = lazy(() => import("src/pages/dashboard/user/yardLocationPage"))

// driver
const DriverListPage = lazy(() => import("src/pages/driver/driver-list"));
const DriverFormPage = lazy(() => import("src/pages/driver/driver-form"));

const EditTabmanger = lazy(() => import("src/pages/driver/new/newTabManger"))
const DriverTabNavigator = lazy(() => import("src/pages/driver/tabs/tabNavigator"))
const GeneralDetails = lazy(() => import("src/pages/driver/tabs/general-details/general-details"))
const DisabilityInsurance = lazy(() => import("src/pages/driver/tabs/disabilityInsurance/disabilityInsurance"))
const DriverExperience = lazy(() => import("src/pages/driver/tabs/drivers-experience/driver-experience"))
const License = lazy(() => import("src/pages/driver/tabs/license/license"))
const CvorListView = lazy(() => import("src/pages/driver/tabs/cvor/view/cvor-list-view"))
const InsuranceDetails = lazy(() => import("src/pages/driver/tabs/insurance-details/insurance-details"))

// client list
const ClientListPage = lazy(() => import("src/pages/client/client-list"))
const ClientFormPage = lazy(() => import('src/pages/client/client-form'))
const ClientTabNavigator = lazy(() => import("src/pages/client/client-tab-navigator"))

// shipment

const ShipmentList = lazy(() => import("src/pages/shipment/shipment-list"))
const ShipmentContainerList = lazy(() => import("src/pages/shipment/shipment-container-list"))

// shipment payment
const ShipmentPayment = lazy(() => import("src/pages/driver-payroll/shipment-payment-list"))

// shipment invoice report
const ShipmentInvoiceReport = lazy(() => import("src/pages/report/shipment-invoice/shipment-invoice-list"))

const ShipmentForm = lazy(() => import("src/pages/shipment/shipment-form"))
const ShipmentTabNavigator = lazy(() => import("src/pages/shipment/shipment-tab-navigator"))
// const ShipmentBoard = lazy(() => import("src/pages/shipment/shipment-board"))

// invoices

const InvoiceList = lazy(() => import("src/pages/invoice/invoice-list"))
const InvoiceForm = lazy(() => import("src/pages/invoice/invoice-form"))
const EmailList = lazy(() => import("src/pages/invoice/email-list"))

// payouts

const DriverPayout = lazy(() => import("src/pages/driver-payroll/driver-payout"))
const DriverPayoutForm = lazy(() => import("src/pages/driver-payroll/driver-payout-form"))

// const DriverPayoutSummary = lazy(() => import("src/pages/driver-payroll/components/tabs/driver-payout-summary/driver-payout-summary"))
// const DriverPayoutList = lazy(() => import("src/pages/driver-payroll/components/tabs/driver-payout-list/driver-payout-list"))

// admin components start

// modules

const ModuleListPage = lazy(() => import("src/pages/module/module-list"))

// shipment master

const Shipline = lazy(() => import("src/pages/shipline/shipline-list"))
const Commodity = lazy(() => import("src/pages/commodity/commodity-list"))
const ContainerSizes = lazy(() => import("src/pages/container-size/container-size-list"))

// NEW USER
const UserFormPage = lazy(() => import('src/pages/user/user-form'));
const UserListPage = lazy(() => import('src/pages/user/user-list'));

const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));

const AccountChangePassword = lazy(() => import('src/pages/user/account-change-password'));

const GroupListPage = lazy(() => import('src/pages/group/group-list'));
const GroupPermissionView = lazy(() => import("src/pages/group/group-permission"))

// const MailPage = lazy(() => import('src/sections/mail/view/mail-view'));


export const dashboardRoutes = [{
  path: 'dashboard',
  element: (
    <AuthGuard>
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    </AuthGuard>
  ),
  children: [
    // {
    //   path: 'mail',
    //   children: [
    //     { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
    //     { path: 'label/:customLabel', element: <MailPage /> },
    //     { path: 'label/:customLabel/:mailId', element: <MailPage /> },
    //     { path: ':systemLabel', element: <MailPage /> },
    //     { path: ':systemLabel/:mailId', element: <MailPage /> },
    //   ],
    // },
    {
      path: "settings",
      children: [
        { element: <IndexPage />, index: true },
        // { path: "info", element: <CompanyInfoPage /> },
        { path: "companies", element: <CompanyList /> },
        { path: "company/:id/modify", element: <CompanyForm /> },
        { path: "company/create", element: <CompanyForm /> },
        { path: "cities", element: <CityList /> },
        { path: "city/:id/modify", element: <CityForm /> },
        { path: "city/create", element: <CityForm /> },
        { path: "zones", element: <ZoneList /> },
        { path: "zone/:id/modify", element: <ZoneForm /> },
        { path: "zone/create", element: <ZoneForm /> },
        { path: "services", element: <ServiceList /> },
        { path: "service/:id/modify", element: <ServiceForm /> },
        { path: "service/create", element: <ServiceForm /> },
      ]
    },
    {
      path: "trucks",
      children: [
        { element: <TruckListPage />, index: true },
        { path: "list", element: <TruckListPage /> },
        { path: "new/:tabName", element: <TruckFormPage /> },
        { path: ":id/modify/:tabName", element: <TruckFormPage /> },
        { path: ":tabName", element: <TruckTabNavigatorView /> },
      ]
    },
    {
      path: "chassis",
      children: [
        { element: <ChassisListPage />, index: true },
        { path: "list", element: <ChassisListPage /> },
        { path: ":id/modify/:tabName", element: <ChassisFormPage /> },
        { path: "new/:tabName", element: <ChassisFormPage /> },
        { path: ":tabName", element: <ChassisTabNavigatorView /> },
      ]
    },

    { path: "yards", element: <YardListPage /> },
    { path: "yard_locations/list", element: <YardLocations /> },

    { path: "vendors", element: <VendorList /> },
    { path: "vendor/:id/modify", element: <VendorForm /> },
    { path: "vendor/create", element: <VendorForm /> },

    // { path: "driver/list", element: < Driver /> },
    // { path: "driver/tabNavigator/:tab", element: < DriverTabNavigator /> },
    // { path: "driver/new/:id", element: < EditTabmanger /> },
    // { path: "driver/:id/modify/:tab", element: < ModifyTabManager /> },

    {
      path: "drivers",
      children: [
        { element: <DriverListPage />, index: true },
        { path: "list", element: <DriverListPage /> },
        { path: ":id/modify/:tabName", element: <DriverFormPage /> },
        { path: "new/:tabName", element: <DriverFormPage /> },

        // { path: "tabNavigator/:tab", element: <DriverTabNavigator /> },
        // { path: "new/:id", element: <EditTabmanger /> },
        // // { path: ":id/modify/:tab", element: <ModifyTabManager /> },
        // // new routes adding 
        // { path: ":id/:type/generalDetails", element: <GeneralDetails /> },
        // { path: ":id/modify/disabilityInsurance", element: <DisabilityInsurance /> },
        // { path: ":id/modify/driversExperience", element: <DriverExperience /> },
        // { path: ":id/modify/license", element: <License /> },
        // { path: ":id/modify/cvor", element: <CvorListView /> },
        // { path: ":id/modify/insuranceDetails", element: <InsuranceDetails /> },
      ]
    },

    {
      path: "clients",
      children: [
        { element: <ClientListPage />, index: true },
        { path: "list", element: <ClientListPage /> },
        { path: ":id/modify/:tab", element: <ClientFormPage /> },
        { path: "create/:tab", element: <ClientFormPage /> },
        { path: "tabNavigator/:tab", element: <ClientTabNavigator /> },
      ]
    },    
    {
      path: "shipment",
      children: [
        { path: "list", element: <ShipmentList />, index: true },
        { path: "containers", element: <ShipmentContainerList /> },
        { path: "create", element: <ShipmentForm /> },
        { path: ":shipmentId/modify/:tab", element: <ShipmentForm /> },
        { path: "tabNavigator/:tab", element: <ShipmentTabNavigator /> },
        // { path: "kanban-board", element: <ShipmentBoard /> },
      ]
    },
    {
      path: "invoices",
      children: [
        { element: <InvoiceList />, index: true },
        { path: "list", element: <InvoiceList /> },
        { path: "new", element: <InvoiceForm /> },
        { path: ":id/modify", element: <InvoiceForm /> },
        { path: "emails", element: <EmailList />},
      ]
    },

    { element: <DriverListPage />, index: true },
        { path: "list", element: <DriverListPage /> },
        { path: ":id/modify/:tabName", element: <DriverFormPage /> },
        { path: "new/:tabName", element: <DriverFormPage /> },

    {
      path: "payments",
      children: [
        
        { path: "payouts", element: <DriverPayout /> , index: true},
        // { path: "payouts/:id", element: <DriverPayoutSummary />},
        // { path: "payouts/:payout_id/driver_payout_summary/:driver_payout_id", element: <DriverPayoutList />},
        { path: "payouts/:id/:tabName", element: <DriverPayoutForm />},
        { path: "payouts/:id/:tabName/:driver_payout_id", element: <DriverPayoutForm />},
        { path: "shipment-payments", element: <ShipmentPayment />},
      ]
    },
    {
      path: "reports",
      children: [
        { path: "shipment-payments", element: <ShipmentPayment />},
        { path: "shipment-invoice-report", element: <ShipmentInvoiceReport />},
      ]
    }, 
    {
      path: "shipmentmaster",
      children: [
        { path: "shipline", element: <Shipline /> },
        { path: "commodities", element: <Commodity /> },
        { path: "containersizes", element: <ContainerSizes /> },
        { path: "run-types", element: <RunTypeList /> },
      ]
    },
    {
      path: 'demo', element: <Demo />
    },    
    { path: 'permission-denied', element: <PermissionDeniedPage /> },
  ]
}, {
  path: 'manage',
  element: (
    <AuthGuard>
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    </AuthGuard>
  ),
  children: [
    {
      path: 'user',
      children: [
        { element: <UserListPage />, index: true },
        { path: 'list', element: <UserListPage /> },
        { path: 'new', element: <UserFormPage /> },
        { path: ':id/edit', element: <UserFormPage /> },
        { path: 'account', element: <UserAccountPage /> },
        { path: 'change_password', element: <AccountChangePassword /> },
      ],
    },
    {
      path: 'group',
      children: [
        { element: <GroupListPage />, index: true },
        { path: 'list', element: <GroupListPage /> },
        { path: 'permissions/:id', element: <GroupPermissionView /> },
      ]
    },
    {
      path: 'module',
      children: [
        { element: <ModuleListPage />, index: true },
        { path: 'list', element: <ModuleListPage /> }
      ]
    },
  ]
}
];
